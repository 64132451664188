<template>
  <div class="article_list_box">
    <div class="ad">
      <img
        style="width: 100%; height: 100%"
        src="../../../assets/img/ywbl.png"
      />
    </div>
    <div class="width_1200 article_list_box11">
      <div class="business_handling_left">
        <p class="business_handling_left_title">开户服务</p>
        <div class="business_handling_left_top">
          <div
            class="business_handling_left_top_item"
            @click="handle_skip('期货开户')"
          >
            <img src="../../../assets/img/bl10.png" />
            <span>期货开户</span>
          </div>
          <div
            class="business_handling_left_top_item"
            @click="handle_skip('特殊品种开户')"
          >
            <img src="../../../assets/img/bl7.png" />
            <span>特殊品种开户</span>
          </div>
          <div
            class="business_handling_left_top_item"
            @click="handle_skip('开户服务')"
          >
            <img src="../../../assets/img/bl8.png" />
            <span>开户服务</span>
          </div>
          <div
            class="business_handling_left_top_item"
            @click="handle_skip('常见问题')"
          >
            <img src="../../../assets/img/bl9.png" />
            <span>常见问题</span>
          </div>
        </div>
        <p class="business_handling_left_title">交易服务</p>
        <div class="business_handling_left_center">
          <img
            src="../../../assets/img/bl6.png"
            @click="handle_skip('账单查询')"
          />
          <img
            src="../../../assets/img/bl5.png"
            @click="handle_skip('交易规则')"
          />
          <img
            src="../../../assets/img/bl1.png"
            @click="handle_skip('应急交易')"
          />
          <img
            src="../../../assets/img/bl4.png"
            @click="handle_skip('银期转账')"
          />
          <img
            src="../../../assets/img/bl2.png"
            @click="handle_skip('业务办理')"
          />
          <img
            src="../../../assets/img/bl3.png"
            @click="handle_skip('表格下载')"
          />
        </div>
        <p class="business_handling_left_title">其他服务</p>
        <div class="business_handling_left_bottom">
          <div
            class="business_handling_left_bottom_img_box"
            @click="handle_skip('软件下载')"
          >
            <img src="../../../assets/img/bl12.png" />
            <img
              class="business_handling_left_bottom_img"
              src="../../../assets/img/bl16.png"
            />
          </div>
          <div
            class="business_handling_left_bottom_img_box"
            @click="handle_skip('交易软件入门')"
          >
            <img src="../../../assets/img/bl11.png" />
            <img
              class="business_handling_left_bottom_img"
              src="../../../assets/img/bl15.png"
            />
          </div>
        </div>
      </div>
      <div class="business_handling_right">
        <p class="business_handling_right_title">
          <span>交易公告</span>
          <span style="cursor: pointer" @click="handle_skip('交易公告')"
            >更多>>
          </span>
        </p>
        <div class="deal_announcement_list">
          <div
            class="deal_announcement_item"
            v-for="item in Notice_list"
            :key="item.id"
          >
            <p @click="handle_(item)">{{ item.title }}</p>
            <span>{{ item.publishTime }}</span>
          </div>
        </div>
        <div class="risk">
          <img
            src="../../../assets/img/bl14.png"
            @click="handle_skip('风险等级详解')"
          />
          <img
            src="../../../assets/img/bl13.png"
            @click="handle_skip('合同文本及风险揭示')"
          />
        </div>
        <p class="business_handling_right_title">
          <span>意见建议</span>
        </p>
        <p class="complaint_advice_text">
          为了进一步提高我们的服务水平，保护客户的权益，无论您对我司有任何意见与建议，请直接拨打恒银期货全国统一客服热线：<span
            >400-0311-779</span
          >
          或通过邮箱<span>hyqhkf@qq.com</span>与我们联系，客服人员将及时为您解答疑问。您的意见我们将在未来的服务中进行改进。
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      Notice_list: []
    };
  },
  async mounted() {
    this.getList();
    this.handle_scrollTop();
  },
  methods: {
    handle_scrollTop() {
      this.$("html,body").animate({ scrollTop: 0 }, 500); //回到顶端
    },
    handle_(data) {
      if (data.hasLink) {
        window.open(data.link)
      } else {
        this.$router.push({
          path: "/details",
          query: {
            my_id: data.id,
            id: '1469167741960225',
          },
        });
      }
    },
    getList() {
      this.$axios({
        method: "get",
        headers: {
          'Authorization': this.cookies.get('token') ? this.cookies.get('token') : '',
        },
        url: `/api/web/cms/list_by_column_name_nested?columnName=交易公告&start=0&limit=8`,
      }).then((response) => {
        let arr = [];
        response.data.rows.forEach((item) => {
          arr.push({
            ...item,
            lastModified: item.lastModified.split(" ")[0],
          });
        });
        this.Notice_list = arr;
      });
    },
    handle_skip(data) {
      if (data === '期货开户') {
        this.$router.push({
          path: "/online_open_account",
          query: {
            column_id: '1460487904559136',
            id: '1457028440522784'
          },
        });
      } else if (data === '特殊品种开户') {
        this.$router.push({
          path: "/details",
          query: {
            my_id: '1470413779501090',
            id: '1469167423193121'
          },
        });
      } else if (data === '开户服务') {
        this.$router.push({
          path: "/details",
          query: {
            my_id: '1470413370556449',
            id: '1469167423193121',
          },
        });
      } else if (data === '常见问题') {
        this.$router.push({
          path: "/article_list",
          query: {
            column_id: '1469893985697825',
            id: '1457028440522784'
          },
        });
      } else if (data === '账单查询') {
        window.open('https://investorservice.cfmmc.com')
      } else if (data === '交易规则') {
        this.$router.push({
          path: "/article_list",
          query: {
            column_id: '1469163289706529',
            id: '1469170346623010'
          },
        });
      } else if (data === '应急交易') {
        this.$router.push({
          path: "/details",
          query: {
            my_id: '1469344544456737',
            id: '1469170346623010'
          },
        });
      } else if (data === '银期转账') {
        this.$router.push({
          path: "/article_list",
          query: {
            column_id: '1460630345220128',
            id: '1469167484010529'
          },
        });
      } else if (data === '业务办理') {
        this.$router.push({
          path: "/article_list",
          query: {
            column_id: '1469883757887521',
            id: '1469892077289505',
          },
        });
      } else if (data === '表格下载') {
        this.$router.push({
          path: "/article_list",
          query: {
            column_id: '1460484383440928',
            id: '1458479904587808'
          },
        });
      } else if (data === '软件下载') {
        this.$router.push({
          path: "/app_download",
          query: {
            column_id: '1457937245536288',
            id: '1458479938142240'
          },
        });
      } else if (data === '交易软件入门') {
        this.$router.push({
          path: "/article_list",
          query: {
            column_id: '1460487904559136',
            id: '1460488139440160'
          },
        });
      } else if (data === '交易公告') {
        this.$router.push({
          path: "/article_list",
          query: {
            column_id: '1469890431025185',
            id: '1469167741960225'
          },
        });
      } else if (data === '风险等级详解') {
        this.$router.push({
          path: "/details",
          query: {
            my_id: '1469344598982689',
            id: '1469167555313698'
          },
        });
      } else if (data === '合同文本及风险揭示') {
        this.$router.push({
          path: "/article_list",
          query: {
            column_id: '1457938828886048',
            id: '1469167555313698'
          },
        });
      }
    }
  },
};
</script>
<style scoped>
.complaint_advice_text > span {
  color: #c21d14;
}
.complaint_advice_text {
  margin-top: 18px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 31px;
}
.risk {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 30px;
}
.deal_announcement_item {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px #e5e5e5 solid;
}
.deal_announcement_item > p,
.deal_announcement_item > span {
  height: 51px;
  line-height: 51px;
}
.deal_announcement_item > span {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  cursor: pointer;
}
.deal_announcement_item > p {
  width: 70%;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  overflow: hidden; /*超出部分隐藏*/
  white-space: nowrap; /*不换行*/
  text-overflow: ellipsis; /*超出部分文字以...显示*/
  cursor: pointer;
}
.deal_announcement_item:hover.deal_announcement_item > p {
  color: #c21d14;
}
.business_handling_right {
  width: 446px;
}
.business_handling_right_title > span {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #000000;
}
.business_handling_right_title {
  background: #f2f2f2;
  display: flex;
  justify-content: space-between;
  padding: 15px;
  border-bottom: #c21d14 2px solid;
}
.business_handling_left_bottom {
  margin-top: 34px;
  display: flex;
  justify-content: space-between;
}
.business_handling_left_bottom_img_box {
  position: relative;
}
.business_handling_left_bottom_img {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
}
.business_handling_left_bottom_img_box:hover
  .business_handling_left_bottom_img {
  display: inline-block !important;
  opacity: 1 !important;
}
.business_handling_left_center {
  margin-top: 4px;
  margin-bottom: 74px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.business_handling_left_center > img {
  margin-top: 30px;
}
.business_handling_left_top_item {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.business_handling_left_top_item > span {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  line-height: 20px;
  margin-top: 18px;
}
.business_handling_left_top {
  margin: 46px 0 75px 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}
.business_handling_left_title {
  font-size: 30px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  border-left: 4px #c21d14 solid;
  padding: 0 0 0 10px;
  line-height: 30px;
}
.business_handling_left {
  width: 690px;
}
.width_1200 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 40px;
}
.box_title {
  position: absolute;
  top: 84px;
  left: 23px;
  font-size: 40px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
}
.img_title {
  width: 100%;
  background: #ccc;
}
.article_list_box {
  box-sizing: border-box;
}
.width_1200 img:hover {
  opacity: 0.7;
  cursor: pointer;
}
.ad {
  width: 100%;
}

.ad img {
  width: 100%;
  height: auto;
}
* {
  box-sizing: border-box;
}
</style>
